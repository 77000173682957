"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIsAddDMSCustomerModalOpen = exports.getDMSCustomer = exports.getDMSAccountMatches = exports.getContact = void 0;
var _commonsUtils = require("@sm360/commons-utils");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getContact = state => {
  const {
    contact
  } = state;
  if (!(0, _commonsUtils.isPlainObject)(contact)) {
    return {};
  }
  return contact;
};

/**
 * @param {Object} state
 * @returns {Array}
 */
exports.getContact = getContact;
const getDMSAccountMatches = state => {
  const {
    DMSAccountMatches
  } = state;
  return Array.isArray(DMSAccountMatches) ? DMSAccountMatches : [];
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getDMSAccountMatches = getDMSAccountMatches;
const getDMSCustomer = state => {
  const {
    DMSCustomer
  } = state;
  if (!(0, _commonsUtils.isPlainObject)(DMSCustomer)) {
    return {};
  }
  return DMSCustomer;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getDMSCustomer = getDMSCustomer;
const getIsAddDMSCustomerModalOpen = state => {
  const {
    isAddDMSCustomerModalOpen
  } = state;
  return isAddDMSCustomerModalOpen;
};
exports.getIsAddDMSCustomerModalOpen = getIsAddDMSCustomerModalOpen;