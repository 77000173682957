"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _actions = require("../../actions");
function* _default() {
  const {
    contactId,
    organizationUnitId
  } = yield (0, _effects.select)(_selectors.getConfig);
  const queryParams = {
    contactId,
    searchContacts: false
  };
  try {
    const response = yield (0, _effects.call)(_commonsApi.ContactService.loadDMSAccountMatches, organizationUnitId, queryParams);
    yield (0, _effects.put)(_actions.Creators.loadDMSAccountMatchesSuccess(response));
    yield (0, _effects.put)(_actions.Creators.setIsAddDMSCustomerModalOpen(true));
    return response;
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'loadDMSAccountMatches'));
    return null;
  }
}